body.modal-open[data-v-9cf42e30] {
    overflow: hidden !important;
    height:100% !important;
}
[data-v-9cf42e30] .body {
    font-size: 12px;
    overflow: hidden !important;
    font-family: Work Sans, sans-serif !important;
}

/*#scroll___BV_modal_outer_ {
    z-index: 99999 !important;
}*/
body.modal-open .modal-dialog[data-v-9cf42e30] {
    overflow: hidden;
    font-family: Work Sans, sans-serif !important;
    color: #18181d !important;
    z-index: 9999;
}
body.modal-open .modal-dialog small[data-v-9cf42e30], p[data-v-9cf42e30], button[data-v-9cf42e30] {
    font-family: Work Sans, sans-serif !important;
}
@media (min-width: 1264px) {
.container[data-v-9cf42e30] {
        max-width: 1185px;
        font-family: Work Sans, sans-serif !important;
}
}
body.modal-open .modal-dialog[data-v-9cf42e30], p[data-v-9cf42e30], button[data-v-9cf42e30] {
    color: #18181d !important;
}
body.modal-open .modal-dialog h1[data-v-9cf42e30], h2[data-v-9cf42e30], h3[data-v-9cf42e30], h4[data-v-9cf42e30], h5[data-v-9cf42e30], h6[data-v-9cf42e30] {
    font-family: Work Sans, sans-serif !important;
    font-weight: 300 !important;
    font-style: inherit !important;
    line-height: inherit !important;
    margin-top: inherit !important;
}
body.modal-open .modal-dialog h5[data-v-9cf42e30] {
    white-space: normal;
    margin: 0;
    font-size: 18px !important;
    line-height: inherit;
    margin-bottom: 0 !important;
    text-transform: inherit;
}

/* Centrer les flèches en hauteur de la photo */
.arrow-container[data-v-9cf42e30] {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
    width: 100%;
    pointer-events: none;
    z-index: 99999;
}

/* Style des flèches */
.arrow[data-v-9cf42e30] {
    pointer-events: auto;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 5px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.left-arrow[data-v-9cf42e30] {
    margin-left: 20px;
}
.right-arrow[data-v-9cf42e30] {
    margin-right: 20px;
}
.close-btn[data-v-9cf42e30] {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    outline: none;
    color: #000; /* Set the color as desired */
    font-size: 1.5rem;
}
body.modal-open .modal-dialog h4[data-v-9cf42e30] {
    color: #18181d !important;
    font-size: 25px !important;
    margin-bottom: 10px !important;
}
body.modal-open .modal-dialog h4[data-v-9cf42e30], h5[data-v-9cf42e30] {
    color: #18181d !important;
}
body.modal-open .modal-dialog h6[data-v-9cf42e30] {
    font-size: 16px !important;
}
[data-v-9cf42e30] body.modal-open .modal-dialog .modal-header {
    padding-bottom: 0;
}
[data-v-9cf42e30] body.modal-open .modal-dialog .modal-body {
    padding-top: 0;
}
[data-v-9cf42e30] p {
    font-size: 16px!important;
    color: #6c757d !important;
}
[data-v-9cf42e30] .btn {
    position: inherit;
    margin: inherit;
    font-size: 14px!important;
    font-weight: inherit;
    text-transform: inherit;
    letter-spacing: 1 inherit;
    z-index: inherit;
    -webkit-border-radius: inherit;
    -moz-border-radius: inherit;
    border-radius: inherit;
    border: inherit;
    -webkit-box-shadow: inherit;
    box-shadow: inherit;
}
[data-v-9cf42e30] small {
    font-size: 14px !important;
}
[data-v-9cf42e30] body.modal-open .modal-dialog label {
    color: inherit;
    letter-spacing: inherit;
    text-transform: inherit;
    margin-bottom: auto;
    font-size: inherit;
    font-weight: inherit;
    display: inherit;
}
[data-v-9cf42e30] .alert {
    font-family: Work Sans, sans-serif !important;
    font-size: 16px;
}
[data-v-9cf42e30] .modal-xl {
    max-width: 100% !important;
    padding-left: 120px;
    padding-right: 120px;
}
@media (max-width: 720px) {
[data-v-9cf42e30] .modal-xl {
        max-width: 100% !important;
        padding-left: inherit;
        padding-right: inherit;
}
}
@media (max-width: 992px) {
.zoom-icon[data-v-9cf42e30] {
        display: none;
}
.vue-share-it-wrapper[data-v-9cf42e30]{
        margin: 10px auto;
        width: 100%;
}
.mb-md-4[data-v-9cf42e30]{
        margin-bottom: 4rem;
}
.media-aside[data-v-9cf42e30]{
        display: none;
}
.text-end[data-v-9cf42e30]{
        justify-content: center;
}
.imageWrapper[data-v-9cf42e30]{
        align-items: baseline !important;;
        height: calc(100vh - 325px) !important;
}
.btn-actions[data-v-9cf42e30]{
        display: none;
}
}
@media (min-width: 992px) {
.zoom-icon[data-v-9cf42e30] {
        position: absolute;
        bottom: 10px;
        right: 10px;
        font-size: 24px;
        cursor: pointer;
}
}
[data-v-9cf42e30] .imageWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 175px); /* Adjust the height based on your needs */
}
[data-v-9cf42e30] .imageWrapper img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}
[data-v-9cf42e30] .modal-header {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
    margin-left: inherit;
    margin-right: inherit;
}
[data-v-9cf42e30] .modal-body {
    margin-top: 0;
    padding-top: 0;
}
[data-v-9cf42e30] .small-image {
    max-height: 200px; /* Modifiez la taille selon vos besoins */
    /* Autres styles pour l'image réduite */
}
[data-v-9cf42e30] label {
    color: #18181d !important;
    margin: 0 !important;
    font-weight: 400;
    font-size: 14px!important;
    font-family: Work Sans, sans-serif !important;
    letter-spacing: inherit;
}
[data-v-9cf42e30] label.formulate-input-label {
    margin-bottom: 10px !important;
    text-transform: inherit;
}
[data-v-9cf42e30] .vue-share-it-wrapper a {
    padding: 0 !important;
}
[data-v-9cf42e30] a {
    color: #0d6efd !important;
}
[data-v-9cf42e30] .light label {
    color: #ffffff !important;
    text-transform: inherit;
}
[data-v-9cf42e30] input {
    -webkit-appearance: none !important;
    -moz-appearance: none;
    appearance: none;
    border-radius: .3em !important;
    border: 1px solid #cecece !important;
    box-sizing: border-box;
    background-color: transparent !important;
    font-size: .9em !important;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    padding: .75em;
    display: block;
    width: 100%;
    font-weight: 400 !important;
    line-height: 1.2em !important;
    margin: 0
}
[data-v-9cf42e30] .formulate-input {
    margin-bottom: 10px;
    width: 100%;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol
}
[data-v-9cf42e30] .formulate-input :deep(.formulate-input-label) {
    display: block;
    line-height: 1.5;
    font-size: 12px;
    margin-bottom: 5px
}
[data-v-9cf42e30] .formulate-input .formulate-input-label--before + .formulate-input-help--before {
    margin-top: -.25em;
    margin-bottom: .75em
}
[data-v-9cf42e30] .formulate-input .formulate-input-element {
    margin-bottom: .1em
}
[data-v-9cf42e30] .formulate-input .formulate-input-help {
    color: #6d6d6d;
    font-size: .7em;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: .25em
}
[data-v-9cf42e30] .formulate-input .formulate-input-errors {
    list-style-type: none;
    padding: 0;
    margin: 0
}
[data-v-9cf42e30] .formulate-input-error {
    color: #960505;
    font-size: 12px;
    font-weight: 300;
    line-height: 1.5;
    margin-bottom: .25em
}
[data-v-9cf42e30] .formulate-input:last-child {
    margin-bottom: 0
}
[data-v-9cf42e30] input::-moz-placeholder {
    color: #a8a8a8;
    line-height: normal
}
[data-v-9cf42e30] input:-ms-input-placeholder {
    color: #a8a8a8;
    line-height: normal
}
[data-v-9cf42e30] input::placeholder {
    color: #a8a8a8;
    line-height: normal
}
[data-v-9cf42e30] input:focus {
    outline: 0;
    border: 1px solid #2169F5
}
.formulate-input-element--date input[data-v-9cf42e30], .formulate-input-element--datetime-local input[data-v-9cf42e30], .formulate-input-element--month input[data-v-9cf42e30], .formulate-input-element--week input[data-v-9cf42e30] {
    min-height: 2.2em
}
.formulate-input-element--search[data-v-9cf42e30] {
    position: relative
}
[data-v-9cf42e30] input[type=color] {
    height: 1.1em;
    box-sizing: content-box;
    width: auto;
    min-width: 5em
}
[data-v-9cf42e30] .formulate-input[data-classification=textarea] textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: .3em;
    border: 1px solid #cecece;
    box-sizing: border-box;
    background-color: transparent;
    font-size: .9em;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    padding: .75em;
    display: block;
    width: 100%;
    font-weight: 400;
    line-height: 1.2em;
    margin: 0;
    min-height: auto;
}
[data-v-9cf42e30] .formulate-input[data-classification=textarea] textarea::-moz-placeholder {
    color: #a8a8a8;
    line-height: normal
}
[data-v-9cf42e30] .formulate-input[data-classification=textarea] textarea:-ms-input-placeholder {
    color: #a8a8a8;
    line-height: normal
}
[data-v-9cf42e30] .formulate-input[data-classification=textarea] textarea::placeholder {
    color: #a8a8a8;
    line-height: normal
}
[data-v-9cf42e30] .formulate-input[data-classification=textarea] textarea:focus {
    outline: 0;
    border: 1px solid #2169F5
}
[data-v-9cf42e30] .formulate-input button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: .3em;
    box-sizing: border-box;
    background-color: transparent;
    font-size: 14px!important;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    padding: .75em;
    display: block;
    width: 100%;
    font-weight: 400;
    line-height: 1.2em;
    margin: 0;
    border: 1px solid #2169F5;
    background-color: #2169F5;
    color: #fff;
    min-width: 0;
    width: auto;
    font-weight: 700;
    cursor: pointer;
    display: inline-flex;
    align-items: center
}
[data-v-9cf42e30] .formulate-input[data-classification=button] button::-moz-placeholder {
    color: #a8a8a8;
    line-height: normal
}
[data-v-9cf42e30] .formulate-input[data-classification=button] button:-ms-input-placeholder {
    color: #a8a8a8;
    line-height: normal
}
[data-v-9cf42e30] .formulate-input[data-classification=button] button::placeholder {
    color: #a8a8a8;
    line-height: normal
}
[data-v-9cf42e30] .formulate-input[data-classification=button] button:focus {
    outline: 0;
    border: 1px solid #2169F5
}
[data-v-9cf42e30] .formulate-input[data-classification=button] button[disabled] {
    background-color: #cecece;
    border-color: #cecece
}
[data-v-9cf42e30] .formulate-input[data-classification=button] button:active {
    background-color: #64c89b;
    border-color: #64c89b
}
[data-v-9cf42e30] .formulate-form-errors {
    margin: .75em 0;
    padding: 0;
    list-style-type: none
}
[data-v-9cf42e30] .formulate-form-errors:first-child {
    margin-top: 0
}
[data-v-9cf42e30] .formulate-form-errors:last-child {
    margin-bottom: 0
}
[data-v-9cf42e30] .formulate-form-errors :deep(.formulate-form-error) {
    color: #960505;
    font-size: .9em;
    font-weight: 300;
    line-height: 1.5;
    margin-bottom: .25em
}
@media (min-width: 420px) {
.double-wide[data-v-9cf42e30] {
        display: flex;
}
.double-wide .formulate-input[data-v-9cf42e30] {
        flex-grow: 1;
        width: calc(50% - .5em);
}
.double-wide .formulate-input[data-v-9cf42e30]:first-child {
        margin-right: .5em;
}
.double-wide .formulate-input[data-v-9cf42e30]:last-child {
        margin-left: .5em;
}
}